import axios from "axios";
import { headerKey } from "./api-header";
import { config } from '../config/config.js';
const API_BASE_URL = config.REACT_APP_BASE_URL;


const getAuctionCategoryList = async () => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/auction/category`, {
    headers: { ...headerKey },
  });
  return response.data;
};

const getAllAuctionsList = async (page, limit, cause_category_id) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/auction`, {
    params: {
      page: page,
      limit: limit,
      cause_category_id: cause_category_id
    }
  },
  {
    headers: { ...headerKey }
  });
  return response.data;
}

const getUpcomingAuctionsList = async (todays_date, page, limit) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/auction/upcoming-auctions/${new Date(todays_date).toISOString()}`, {
    params: {
      page: page,
      limit: limit
    }
  },
  {
    headers: { ...headerKey }
  });
  return response.data;
}

const getAllBuyNowItems = async (page, limit) => {
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/action-items/all-buynow-items`, {
      params: {
        page: page,
        limit: limit
      }
    },
    {
        headers: { ...headerKey }
    });
    return response.data;
}

const getAllOnlyAuctionItems = async (page, limit) => {
  const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/action-items/all-only-auction-items`, {
    params: {
      page,
      limit
    },
    headers: {...headerKey }
  })
  return data
}

const getCurrentAuctions = async (todays_date, page, limit) => {
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/active-auctions/${new Date(todays_date).toISOString()}`, {
      params: {
        page: page,
        limit: limit
      }
    },
    {
        headers: { ...headerKey }
    });
    return response.data;
}

const getAuctionById = async (auctionId,showIncompleted=false, getCauseImage) => {
  try{
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}?showIncompleted=${showIncompleted}`, {
      params: {
        getCauseImage:getCauseImage
      }
    },
    { 
        headers: { ...headerKey }
    });
    return response.data;
  }catch(error){
    console.log(error)
  }
}

const getAuctionItemsById = async (auctionId, page, limit, sortMethod=null, priceRangeStart, priceRangeEnd, itemtype, searchText, userId, adminPreview, filters) => {
  try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/items?page=${page}&limit=${limit}`, 
    {
      params: {
        sortMethod:sortMethod,
        priceRangeStart: priceRangeStart,
        priceRangeEnd: priceRangeEnd,
        itemtype:itemtype,
        searchText,
        userId,
        adminPreview,
        filters
      }
    },
    {
      headers: { ...headerKey }
    });
    return response.data;
  }
  catch(error) {
      throw error
  }
}

const getAuctionItemsByCategoryId = async (page, limit, auction_item_category_id,auction_id, sortMethod, priceRangeStart, priceRangeEnd, itemFlag, userId, adminPreview, filters) => {
  let param=auction_id?{
    page: page,
    limit: limit,
    auction_item_category_id: auction_item_category_id,
    auction_id:auction_id,
    sortMethod:sortMethod,
    priceRangeStart: priceRangeStart,
    priceRangeEnd: priceRangeEnd,
    itemFlag:itemFlag,
    userId,
    adminPreview,
    filters
     }:{
    page: page,
    limit: limit,
    auction_item_category_id: auction_item_category_id,
    market_place:true,
    sortMethod:sortMethod,
    priceRangeStart: priceRangeStart,
    priceRangeEnd: priceRangeEnd,
    userId,
    adminPreview,
    filters
  };
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/auction/items-by-category`, {
      params: param
    },
    {
      headers: { ...headerKey }
    });
    return response.data;
    }catch(error) {
      throw error
    }
}

const getAuctionItemDetailsById = async (auctionId, itemId, xrefAuctionId=null) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/item/id/${itemId}?xrefAuctionId=${xrefAuctionId}`, {
      headers: { ...headerKey }
    });
    return response.data;
  }
  catch(error) {
    throw error
  }
}

const getBidDetailsOfItemById = async (auctionId, itemId) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/item/id/${itemId}/bid`, {
    headers: { ...headerKey }
  });
  return response.data;
}
const fetchBidDetailsOfItemById = async (auctionId, itemId) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/item/id/${itemId}/bid-details`, {
    headers: { ...headerKey }
  });
  return response.data;
}

const placeNewBid = async (auctionId, itemId, payload) => {
  const response = await axios.post(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/item/id/${itemId}/bid`, payload,
  {
    headers: { ...headerKey }
  });
  return response.data;
}

const getHighestBidOfItem = async (itemId) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/bid/item/${itemId}/highest-bidder`, {
    headers: { ...headerKey }
  });
  return response.data;
}

const getAllItemsByCauseCategory = async (page, limit, cause_category_id, sortMethod, priceRangeStart, priceRangeEnd, userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/items-by-cause_category`, {
      params: {
        page: page,
        limit: limit,
        cause_category_id: cause_category_id,
        sortMethod:sortMethod,
        priceRangeStart: priceRangeStart,
        priceRangeEnd: priceRangeEnd,
        userId
      }
    },
    {
      headers: { ...headerKey }
    });
    return response.data;
  }
  catch(error) {
    throw error
  }
}

const getBidHistoryOfItemById = async (auctionId, itemId) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/item/id/${itemId}/bid_history`, {
    headers: { ...headerKey }
  });
  return response.data;
}
const getAuctionItemByAuctionId = async (auctionId,page,limit, sortMethod=null, priceRangeStart, priceRangeEnd, searchText, userId, filters) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/auction-item-by-id?page=${page}&limit=${limit}`, 
      {
        params: {
          sortMethod:sortMethod,
          priceRangeStart: priceRangeStart,
          priceRangeEnd: priceRangeEnd,
          searchText,
          userId,
          filters
        }
      },
      {
        headers: { ...headerKey }
      });
      return response.data;
  }catch(error) {
    throw error
  }
}
const getBuyNowItemsByAuctionId = async (auctionId,page,limit, sortMethod=null, priceRangeStart, priceRangeEnd, searchText, userId, adminPreview, filters) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/buy-now-item-by-auction-id?page=${page}&limit=${limit}`, 
  {
    params: {
      sortMethod:sortMethod,
      priceRangeStart: priceRangeStart,
      priceRangeEnd: priceRangeEnd,
      searchText:searchText,
      userId,
      adminPreview,
      filters
    }
  },
  {
    headers: { ...headerKey }
  });
  return response.data;
}
const getCustomCategoryHeadingByAuctionId = async (auctionId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/custom-category-headline`, {
      headers: { ...headerKey }
    });
    return response.data;
  }
  catch(error) {
    throw error
  }
}
const getItemsByCustomCategoryByAuctionId = async (auctionId, customCategoryId, page,limit, sortMethod, priceRangeStart, priceRangeEnd, itemFlag, userId, filterByName, adminPreview, filters) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/custom-category/${customCategoryId}?page=${page}&limit=${limit}`, 
    {
      params: {
        sortMethod:sortMethod,
        priceRangeStart: priceRangeStart,
        priceRangeEnd: priceRangeEnd,
        itemFlag:itemFlag,
        userId,
        filterByName,
        adminPreview,
        filters
      }
    },
    {
      headers: { ...headerKey }
    });
    return response.data;
  }
  catch(error) {
    throw error
  }
}

const findAuctionGoal = async (auctionId) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/auction-goal`, {
    headers: { ...headerKey }
  });
  return response.data;
}

const getAllCompletedAuctionsList = async (page, limit) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/auction/completed-auctions`, {
    params: {
      page: page,
      limit: limit,
    }
  },
  {
    headers: { ...headerKey }
  });
  return response.data;
}

const getAllPreviewAuctionsList = async (page, limit) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/auction/preview-auctions`, {
    params: {
      page: page,
      limit: limit,
    }
  },
  {
    headers: { ...headerKey }
  });
  return response.data;
}

const secionsConfiguration = async () => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/pages/configurations`);
  return response.data;
}

const getAllFeaturedItems = async(page, limit) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/get-all-featuredItems?page=${page}&limit=${limit}`,{
      headers: { ...headerKey }
    })
    return response.data
  } catch (error) {
    
  }
}

const getAuctionCategoryListHaveItem = async (auctionId, itemFlag) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/category/${auctionId}`, {
      params:{itemFlag},
      headers: { ...headerKey },
    });
    return response.data;
  }
  catch(error) {
    throw error;
  }
};

const getFeaturedAuctionsList = async (auctionType, page, limit) => {
   try{
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/featured-auction?auctionType=${auctionType}`,
    {
      params: {
        page,
        limit
      }
    },
      {
        headers: { ...headerKey },
      }
    );
    return response.data;
   }catch (error) {
    throw error
   }
};

const fetchAllMarketPlaceItems = async (page, limit) => {
  try{
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/items/get-all-marketplace-items?page=${page}&limit=${limit}`, {
      headers: { ...headerKey }
    });
    return response.data;
  }
  catch(error) {
    throw error;
  }
}

const getAuctionsByCauseId = async (causeId, page=1, limit=20) => {
  try{
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/get-events-by-cause/${causeId}?page=${page}&limit=${limit}`, {
      headers: { ...headerKey }
    });
    return response.data;
  }
  catch(error) {
    throw error;
  }
}

const getAllStores = async (page=1, limit=20) => {
  try{
    const response = await axios.get(`${API_BASE_URL}/api/v1/auction/get-all-stores?page=${page}&limit=${limit}`, {
      headers: { ...headerKey }
    });
    return response.data;
  }
  catch(error) {
    throw error;
  }
}

const fetchItemsByFiltering = async(sortMethod, page, limit, groupMethod, auctionId, itemType, priceRangeStart, priceRangeEnd, categoryId, userId, adminPreview, filters) => {

  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/filter-items/${groupMethod}`,{
      params: {
        page: page,
        limit: limit,
        itemType: itemType,
        sortMethod: sortMethod,
        groupMethod: groupMethod,
        priceRangeStart: priceRangeStart,
        priceRangeEnd: priceRangeEnd,
        categoryId:categoryId, /// for browse by category page
        userId,
        adminPreview,
        filters
      }
    }, {
      headers: { ...headerKey }
    });
    return data;
  } catch(error) {
    throw error;
  }
}

const fetchItemsByFilteringWithoutAuctionId = async(sortMethod, page, limit, groupMethod, itemType, priceRangeStart, priceRangeEnd, categoryId, userId, adminPreview, filters) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/filter-items/${groupMethod}`,{
      params: {
        page: page,
        limit: limit,
        itemType: itemType,
        sortMethod: sortMethod,
        groupMethod: groupMethod,
        priceRangeStart: priceRangeStart,
        priceRangeEnd: priceRangeEnd,
        categoryId:categoryId, /// for browse by category page
        userId,
        adminPreview,
        filters
      }
    }, {
      headers: { ...headerKey }
    });
    return data;
  } catch(error) {
    throw error;
  }
}

const getCategoryById = async(categoryId, itemType) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/get-category-by-id/${categoryId}`, 
    {
      params: {
        itemType: itemType,
      }
    },
    {
      headers: { ...headerKey }
    });
    return data;
  }catch(error) {
    throw error
  }
}

const getItemsForShopNow = async(page, limit , filters, isFilters=false, selectedSortValue, filterByName, searchText) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/get-shop-now-products`, 
    {
      params: {
        page,
        limit,
        filters,
        isFilters,
        selectedSortValue,
        filterByName,
        searchText
      }
    },
    {
      headers: { ...headerKey }
    });
    return data;
  }catch(error) {
    throw error
  }
}

const getCustomCategoryHeadingByAuctionIdHaveItem = async(auctionId, itemFlag, needHeading=true, groupByName) => {
  try{
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${auctionId}/custom-category-headline-have-item`, {
      params: {
        itemFlag,
        needHeading,
        groupByName
      }
    })
    return data
  }catch(error) {
    throw error
  }
}

const fetchAllAvailableCategories = async() => {
  try{
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/category-have-item`)
    return data
  }catch(error) {
    throw error
  }
}

const getInstaPosts = async() => {
  try{
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/get-insta-posts`)
    return data
  }catch(error) {
    throw error
  }
}

const getAllCategoriesAndCustomCategories = async(showEveryWhere=true, groupByName=false) => {
  try{
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/get-all-categories`, {
      params: {
        showEveryWhere,
        groupByName
      }
    })
    return data
  }catch(error) {
    throw error
  }
}
const getExclusiveEventImage = async() => {
  try{
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/get-exclusive-event-image`)
    return data
  }catch(error) {
    throw error
  }
}

const auctionServices = {
  getAuctionCategoryList,
  getAllAuctionsList,
  getUpcomingAuctionsList,
  getAllBuyNowItems,
  getAllItemsByCauseCategory,
  getCurrentAuctions,
  getAuctionById,
  getAuctionItemsById,
  getAuctionItemsByCategoryId,
  getAuctionItemDetailsById,
  getBidDetailsOfItemById,
  placeNewBid,
  getHighestBidOfItem,
  getBidHistoryOfItemById,
  getAllOnlyAuctionItems,
  getAuctionItemByAuctionId,
  getBuyNowItemsByAuctionId,
  getCustomCategoryHeadingByAuctionId,
  getItemsByCustomCategoryByAuctionId,
  findAuctionGoal,
  getAllCompletedAuctionsList,
  getAllPreviewAuctionsList,
  secionsConfiguration,
  getAllFeaturedItems,
  fetchBidDetailsOfItemById,
  getAuctionCategoryListHaveItem,
  getFeaturedAuctionsList,
  fetchAllMarketPlaceItems,
  getAuctionsByCauseId,
  getAllStores,
  fetchItemsByFiltering,
  getCategoryById,
  fetchItemsByFilteringWithoutAuctionId,
  getCustomCategoryHeadingByAuctionIdHaveItem,
  fetchAllAvailableCategories,
  getInstaPosts,
  getAllCategoriesAndCustomCategories,
  getItemsForShopNow,
  getExclusiveEventImage
};

export default auctionServices;
