import React, { useState, useEffect, useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {  useNavigate } from "react-router-dom";

// import BeBoundlessBalloon from "../../images/beboundless_1_1_balloon.png"

import {FilterContext} from "../../store/filter-context";

import auctionServices from "../../services/auction-services";
import NoData from "./NoData";
import donorServices from "../../services/donor-services";
import logger from "../../utils/logger";
import {useDispatch} from "react-redux"
import {Helmet} from "react-helmet-async"
import {resetItems} from "../../redux/itemsReducer"
import { Desktop, Mobile } from "./responsive/Responsive";

import classes from "../desktop/styles/Home.module.css"
import SubForm from "../desktop/components/SubForm/SubForm";

import FeaturedItems from "./organisms/HomePageComponents/FeaturedItems/FeaturedItems";
import BrowsByDonor from "./organisms/HomePageComponents/BrowsByDonor/BrowsByDonor";
import BrowsByCause from "./organisms/HomePageComponents/BrowsByCause/BrowsByCause";
import BrowsByCategory from "./organisms/HomePageComponents/BrowsByCategory/BrowsByCategory";
import Events from "./organisms/HomePageComponents/Events/Events";
import Section7 from "./organisms/CreateEvent/Components/Section7";
import Banner from "./organisms/HomePageComponents/Banner/Banner";
import AboutUs from "./organisms/HomePageComponents/AboutUs/AboutUs";
import SplashCarosel from "./organisms/HomePageComponents/NewHomePageComponents/SplashCarosel/SplashCarosel";
import NonProfits from "./organisms/HomePageComponents/NewHomePageComponents/NonProfits/NonProfits";
import HeaderV2 from "./molecules/Header/HeaderV2";
import BrandSection from "./organisms/HomePageComponents/NewHomePageComponents/BrandSection/BrandSection";
import HostingSection from "./organisms/HomePageComponents/NewHomePageComponents/HostingSection/HostingSection";
import MobileBanner from "./organisms/HomePageComponents/NewHomePageComponents/MobileBanner/MobileBanner";
import BgLearnMore from "./organisms/HomePageComponents/NewHomePageComponents/BGLearnMore/BgLearnMore";
import FeaturedEvents from "./organisms/HomePageComponents/NewHomePageComponents/FeaturedEvents/FeaturedEvents";
import BeTheFirst from "./organisms/HomePageComponents/NewHomePageComponents/BeTheFirst/BeTheFirst";
import NewFooter from "./molecules/NewFooter/NewFooter";
import LoadingScreen from "./LoadingScreen/Loading";
import MarketPlaceDesktopTop from "./organisms/HomePageComponents/NewHomePageComponents/MarketPlaceDesktopTop/MarketPlaceDesktopTop";
import FeaturedShop from "./organisms/HomePageComponents/NewHomePageComponents/FeaturedShop/FeaturedShop";
import {config} from "../../config/config";
import ShopBrandsCausesEvents from "./organisms/HomePageComponents/NewHomePageComponents/ShopBrandsCausesEvents/ShopBrandsCausesEvents";
import BoundlessCauses from "./organisms/HomePageComponents/NewHomePageComponents/BoundlessCauses/BoundlessCauses";
import FeaturedEventsV2 from "./organisms/HomePageComponents/NewHomePageComponents/FeaturedEvents/FeaturedEventsV2/FeaturedEventsV2";
import NewFooterV2 from "./molecules/NewFooter/NewFooterV2/NewFooterV2";
import HeaderV3 from "./molecules/Header/HeaderV3/HeaderV3";
import { clearAllBrowseBySelectedFilters } from "../../redux/browseItemsReducer";
const {REACT_APP_URL} = config

const configIDMapper = {
  // 1: Section2,
  1: HostingSection,
  2: FeaturedItems,
  3: BrowsByDonor,
  4: BrowsByCause,
  5: BrowsByCategory,
  6: Events,
  // 7: Section7,
  7: BrandSection,
  8: Banner,
  9: SubForm,
  10: AboutUs,
  11: SplashCarosel,
  12: NonProfits,
  13: MobileBanner,
  14: BgLearnMore,
  // 15: FeaturedEvents,
  15: FeaturedEventsV2,
  16: BeTheFirst,
  17: MarketPlaceDesktopTop,
  18: FeaturedShop,
  19: ShopBrandsCausesEvents,
  20: BoundlessCauses
}

export const mobileStyle = {
  // red, black, shadywhite, transparent, white
  backgroundColor:['#FC3300', "#30313A", "#F0E8E3", "transparent", "#ffffff" ],
  color:['#FC3300', "#30313A", "#F0E8E3","black"]
}

function Home({ causeCategoryList, auctionCategoryList }) {
  const [upcomingAuctions, setUpcomingAuctions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [donorData, setDonorData] = useState(null)
  const [completedAuctionList,setcompletedAuctionList]=useState([])
  const {reset} = useContext(FilterContext)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [sectionsConfigurationMobile, setSectionsConfigurationMobile] = useState([])
  const [sectionsConfigurationDesktop, setSectionsConfigurationDesktop] = useState([])


  useEffect(() => {
    reset()
    dispatch(resetItems())
    dispatch((clearAllBrowseBySelectedFilters()))
    localStorage.removeItem("browseScroll")

  }, [])

  useEffect(() => {
    setIsLoading(true);

    const getRandomDonor = async () => {
      try {
        const data = await donorServices.randomDonor();
        logger('./pages/components/Home.jsx','getRandomDonor(): res',data);
        setDonorData(data);
      } catch (error) {
        logger('./pages/components/Home.jsx','getRandomDonor(): catch',error);
        setIsLoading(false);
      }
    }

    const getPagesConfiguration = async () => {
      try {
        const data = await auctionServices.secionsConfiguration();
        const newConfigMobile =data.data?.mobile && data.data?.mobile.map(conf => {
          const Component = configIDMapper[conf.id]
          return {
            ...conf,
            Component: ({containerStyle, cardStyle, section7Style, section2Style, section2ContainerStyle}) => <Component mobileStyle={mobileStyle} cardStyle={cardStyle} containerStyle={containerStyle} title={conf.title} subtext={conf.subtext} extraData={conf.extra_data} buttonText="LEARN MORE" linkTo="/create-event" section7Style={section7Style} section2Style={section2Style} section2ContainerStyle={section2ContainerStyle}/>
          }
        })
        const newConfigDesktop =data.data?.desktop&& data.data?.desktop.map(conf => {
          const Component = configIDMapper[conf.id]
          return {
            ...conf,
            Component: ({containerStyle, cardStyle, section7Style, section2Style, section2ContainerStyle}) => <Component mobileStyle={mobileStyle} cardStyle={cardStyle} containerStyle={containerStyle} title={conf.title} subtext={conf.subtext} extraData={conf.extra_data} buttonText="LEARN MORE" linkTo="/create-event" section7Style={section7Style} section2Style={section2Style} section2ContainerStyle={section2ContainerStyle}/>
          }
        })
        setSectionsConfigurationMobile(newConfigMobile)
        setSectionsConfigurationDesktop(newConfigDesktop)

        // setSectionsConfiguration(newConfig)
        setIsLoading(false);
      } catch (error) {
        logger('./pages/components/Home.jsx','getPagesConfiguration(): catch',error);
        setIsLoading(false);
      }
    }

    getPagesConfiguration()
    // getRandomDonor();

  }, []);

  const aboutUs = () => {
    navigate('/about');
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        {/* <title>Home | Boundless Giving</title> */}
        <title>Boundless Giving - Best Online Fundraising Platform</title>
        <meta 
          name="description" 
          content="Looking for charitable giving platforms? Boundless Giving is an innovative online fundraising platform where 80% of every sale goes directly to nonprofits." 
        />
        <meta 
          name="keywords"
          content="Online Fundraising Platform"
        />
        <link rel="canonical" href={`${REACT_APP_URL}`} />
      </Helmet>
        {/* <HeaderV2 primaryColor={mobileStyle.color[1]} transparentNav={true} containerStyle={{position:"fixed"}} primaryBackgroundColor={mobileStyle.backgroundColor[2]}/> */}
        <HeaderV3/>
      <Desktop>
        {/* <Navbar /> */}
        {/* <SubNavbar /> */}
        {isLoading ? (
           <LoadingScreen bgColor="red"/> 
          ) : (
        <div className={classes.dhome_container}>

       {/* <Banner/> */}
        {/* <Section2 /> */}

        <div
        //  style={{marginBottom: "50px"}}
         >
          {sectionsConfigurationDesktop.map(({Component,id}) => <Component key={id} mobileStyle={mobileStyle} containerStyle={{backgroundColor: "#f4f4f4", boxShadow: "none"}} cardStyle={{border: "1px solid #603F8B"}} />)}
        </div>


        {/* <section className={`${classes.dsection} ${classes.dup_for}`}>
          <h3 onClick={() => navigate("/brand-partner", {preventScrollReset: true})} className={classes.dhome_learnmore}>Learn More About How to Become a Brand Partner and<br/>Donate to Worthy Causes in the Boundless Community</h3>
          <div>
            <img onClick={() => navigate("/brand-partner", {preventScrollReset: true})} style={{cursor: "auto"}} src={HomeHeader2} alt="" />
          </div>
       </section> */}

        

       
        
        </div>)}
        {/* <SubForm /> */}
        {/* <Footer /> */}
      </Desktop>


      <Mobile className="page_outer homePage">
        <div className="container">
          {/* <div className="header_sec">
            <span>
              <img className="headLogo" src={Logo1} alt="" />
            </span>
           <div style={{position:"absolute",right:'15px'}}> <CartIcon color="#274886"/></div>
          </div> */}
          {/* <HeaderV2 primaryColor={mobileStyle.color[2]} transparentNav={true} containerStyle={{position:"fixed"}} /> */}
          {isLoading ? (
           <LoadingScreen bgColor="red"/> 
          ) : (
            <main style={{backgroundColor:mobileStyle.backgroundColor[4]}} className="homePageMain" >
              

              {/* Popup */}
              {/* <div style={{margin: "0", padding: "0"}}>
                <div className="page_heading">
                    <h3>
                      Browse The Pop-Up
                    </h3>
                </div>
                <div 
                  style={{position: "relative"}}
                  className="buy_now_div">
                  <img 
                    onClick={() =>
                      navigate("/browse-items", {
                        state: { buyNow: true, title: "Buy now", from: 'buyNow' },
                      })
                    }
                    src={ShopImage} alt="" />
                </div>
              </div> */}

              {/* Items For Auction */}
              {/* <div style={{margin: "40px 0"}}>
                <div className="page_heading">
                  <h3>
                    Browse Items Up For Auction
                  </h3>
                </div>
                <div 
                    style={{position: "relative"}}
                    className="buy_now_div">
                    <img 
                      onClick={() =>
                        navigate("/browse-items", {
                          state: { from: 'auction' },
                        })
                      }
                      src={AuctionBrowse} alt="" />
                </div>
              </div> */}


              {/* Donor Gives */}
              {/* <div style={{margin: "0px"}}>
                <div className="page_heading">
                    <h3>
                    {`${donorData?.data?.org_name} `}Gives
                    </h3>
                </div>
                <div 
                  style={{position: "relative"}}
                  className="buy_now_div">
                  <img 
                    onClick={() => navigate("/browse-items", 
                    {state: 
                      {from: "donor", donor: donorData?.data, donorImage: donorData?.assets?.length > 0 ? donorData?.assets[0] : ""}
                    })}
                    src={donorData?.assets?.length > 0 ? donorData?.assets[0] : ""} alt="" />
                </div>
              </div> */}

              {/* Upcoming Events */}
              {/* {upcomingAuctions && upcomingAuctions?.data?.length > 0 ? (
                <div style={{margin: "0px", paddingTop: "40px"}}>
                  <div className="page_heading" style={{marginBottom: "5px"}}>
                    <h3>
                      Upcoming Events
                      <span>
                        <Link
                          to="/auction?type=upcoming"
                          className="viewAllText"
                        >
                          View All
                        </Link>
                      </span>
                    </h3>
                  </div>
                  <div
                    style={{ display: "flex", overflow: "scroll" }}
                    className="auctionDiv2"
                  >
                    {upcomingAuctions &&
                      upcomingAuctions?.data?.length > 0 &&
                      upcomingAuctions?.data?.map((i) => (
                        <div
                          key={i.id}
                          className="bigImg"
                          style={{ minWidth: "70%" }}
                          onClick={() => navigate(`/event`, {state:{ auction : i, image: i?.image2, from: 'event' }})}
                        >
                          <img
                            src={i.image1}
                            alt=""
                            style={{ borderRadius: "10px" }}
                          />
                          <div className="imgLabel">
                            <p className="imgHeading">{i.title}</p>
                            <p className="imgDesc">
                              Event opens on{" "}
                              {moment(i.start_date).format("MMMM DD YYYY")}{" "}
                              {moment(i.start_date)
                                .tz("America/Los_Angeles")
                                .format("ha z")}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div style={{margin: "0px", paddingTop: "40px"}}>
                  
                </div>
              )} */}

            

              {sectionsConfigurationMobile.map(({Component,i}) => <Component  mobileStyle={mobileStyle} section7Style={{marginLeft:"0px"}} section2Style={{marginTop:"0px"}} section2ContainerStyle={{ padding: "0px 10px 40px"}}/>)}



              {/* Email Update */}
              {/* <div style={{margin: "0px", paddingTop: "20px"}}>
                <NoData />
              </div> */}

              {/* About Us */}
              
            </main>
          )}
        </div>
      </Mobile>
         {/* {!isLoading && <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[2]} primaryColor={mobileStyle.color[1]} logoColor={mobileStyle.color[0]} />} */}
         {!isLoading && <NewFooterV2 />}
    </>
  );
}

export default Home;
