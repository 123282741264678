import React, { useEffect , useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import userServices from '../../../../services/user-services';
import AuthContext from '../../../../store/auth-context';
import VariantsSelector from '../VariantsSelector/VariantsSelector';
import AddIcon from '@mui/icons-material/Add';
import {InfoOutlined} from '@mui/icons-material';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { calculateTimeLeft } from '../../../../utils/util-functions';
import moment from "moment-timezone";
import './itemInfo.css';
import { mobileStyle } from '../../Home';
import { Desktop, Mobile } from '../../responsive/Responsive';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import FavouritesButton from '../../atoms/FavouritesButton/FavouritesButton';
import constants from '../../../../constants/en';
import { getFavouriteItems } from '../../../../redux/favouritesReducer';
import { Tooltip } from '@mui/material';
import TooltipComponent from '../../atoms/TooltipComponent/TooltipComponent';

const ItemInfo = ({
  auctionId,
  itemId,
  lotDetails,
  selectedOptions,
  setSelectedOptions,
  selectedVaraint,
  setSelectedVariant,
  bidDetails,
  highlight,
  setAuctionActive,
  causeName,
  cartItem,
  setCartItem,
  quantity,
  setQuantity=()=>null,
  handleQuantityIncrement,
  handleQuantityDecrement,
  refreshPage,
  loading,
  auctionComplete,
  xrefCauseData=null,
  setHasQuantity=()=>null,
  hasQuantity=false,
  setOpenConfirmAlert=()=>null,
  getFinalCauseAndAuctionId=()=>null,
  setModal=()=>null,
  setAlertState=()=>null,
  eventStatus=''
}) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const authCtx = useContext(AuthContext);
  const [isFavourite, setIsFavourite] = useState(false)
  const primaryColor = mobileStyle.color[1];
  const secondaryColor = mobileStyle.color[0];
  const whiteColor = mobileStyle.color[2];
  const isDesktop = useMediaQuery({
    minWidth: 768
  })

  useEffect(() => {
    let id;
    if (!lotDetails?.store_item_id) {
      id = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(moment(lotDetails?.end_date)));
      }, 1000);
    }
    else {
      id = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(moment(lotDetails?.auction?.end_date)));
      }, 1000);
    }
    return () => {
      clearTimeout(id);
    };
  });
  useEffect( ()=>{
    // setQuantity(1)
    // if(!authCtx.isLoggedIn){
    //   return
    // }
      ( async function()  { 
       if(lotDetails?.store_item !== null){
           if(lotDetails?.variants.length === 0) {
         await userServices.checkCartItem(lotDetails?.store_item?.sku, authCtx.isLoggedIn).then((res)=>{
          if(res?.status===200){
            setCartItem(true)
          }else{
             setCartItem(false)
           }
              }).catch((err)=>{
               throw (err)
              })
           }else{
            await  userServices.checkCartItem(selectedVaraint?.sku, authCtx.isLoggedIn).then((res)=>{
               if(res?.status===200){
                setCartItem(true)
              }else{
                 setCartItem(false)
               }
              }).catch((err)=>{
               throw (err)
              })
           }
         }})()   
         setQuantity(1) 
     },[selectedVaraint])

  const getCauseAmount = () => {

    var amt = 0;

    // if(lotDetails?.start_date && (new Date(lotDetails?.start_date) > new Date())){
    //   if(timeLeft && !timeLeft.seconds){
    //     return 0;//Closed
    //   }
    // }
    
    // if(lotDetails?.store_item && Object.keys(lotDetails?.variants).length === 0 && lotDetails?.store_item.quantity_in_stock <= 0){
    //   return 0;//Sold out
    // }
    // else if(lotDetails?.variants && Object.keys(lotDetails?.variants).length > 0 && (selectedVaraint && selectedVaraint.quantity_in_stock <= 0)){
    //   return 0;//Sold out
    // }
    const causeSharePercentage = (+lotDetails?.donor?.revenue_splits[0]?.cause_rev_share_percent / 100) || 0.8
  
    if(!lotDetails?.store_item_id){
      //Auction
      if(bidDetails?.data?.bidCount > 0){
        amt = Number(bidDetails?.data?.bid_amount * causeSharePercentage);
      }
      else{
        amt = Number(bidDetails?.data?.price * causeSharePercentage);
      }
    }
    else if(lotDetails?.buy_now_price){
      //Fixed price
      if(!lotDetails?.store_item_id){
        amt = Number(lotDetails.buy_now_price * causeSharePercentage);
      }
      else {
        if(Object.keys(lotDetails?.variants).length === 0){
          amt = Number(lotDetails?.store_item.price * causeSharePercentage);
        }
        else if(selectedVaraint){
          amt = Number(selectedVaraint.price * causeSharePercentage);
        }
      }
    }
  
    return amt * (quantity || 1);
  };

  const getQty = () => {
    // if(new Date(lotDetails?.auction?.end_date) > new Date()){
      if(!auctionComplete){
    if(Object.keys(lotDetails?.variants).length === 0){
      if(lotDetails?.store_item.quantity_in_stock > 0){
        if(lotDetails?.store_item.quantity_in_stock < 5){
          return <span style={{color: secondaryColor}}>Only {lotDetails?.store_item.quantity_in_stock} left in stock - order soon</span>;
        }
        else{
          return <span style={{color: "#379237"}}>In Stock</span>
        }
      }
      else{
        return <span style={{color: secondaryColor}}>Sold Out</span>
      }
    }
    else{
      if(selectedVaraint && selectedVaraint.quantity_in_stock > 0){
        if(selectedVaraint && selectedVaraint.quantity_in_stock < 5){
          return <span style={{color: secondaryColor}}>Only {selectedVaraint.quantity_in_stock} left in stock - order soon.</span>;
        }
        else{
          return <span style={{color: "#379237"}}>In Stock</span>
        }
      }
      else{
        return <span style={{color: secondaryColor}}>Sold Out</span>
      }
    }
  }
  else{
    return <span style={{color: secondaryColor}}>Event Completed</span>
  }
}

function getSku() {
  let sku = lotDetails?.sku
    if(lotDetails?.store_item !== null) {
      if(lotDetails?.variants?.length) {
        sku = selectedVaraint?.sku
      }
      else {
        sku = lotDetails?.store_item?.sku
      }
    }
    return sku;
}

const checkItemInFavourites = async() => {
  if(!authCtx.isLoggedIn){
    return
  }
  try {
    let sku = getSku()
    if(!sku) return;
    const response = await userServices.checkFavouriteItem(sku)
    setIsFavourite(response?.isFavourite)
  }catch(error) {
    setIsFavourite(false)
  }
} 

useEffect(() => {
  checkItemInFavourites()
},[selectedVaraint])


const handleAddToFavourites = async() => {
  if(!authCtx.isLoggedIn){
    setOpenConfirmAlert(true)
    return
  }
  try {
    let auction_item_id = lotDetails.id;
    let item_sku = "";
    if(lotDetails.variants.length > 0){
      item_sku = selectedVaraint?.sku;
    }else if(lotDetails.store_item !== null){
      item_sku = lotDetails.store_item.sku
    }else{
      item_sku = lotDetails.sku
    }
    const {finalAuctionId,finalCauseId} = getFinalCauseAndAuctionId()
    const body = {
      auction_item_id,
      item_sku,
      cause_id:finalCauseId,
      auction_id:finalAuctionId
    }
    const response = await userServices.addToFavourites(body)
    setIsFavourite(response?.data?.isFavourite)
    dispatch(getFavouriteItems())
    setAlertState({isOpen:true, message: response.data.message})
  }catch(error) {
    setAlertState({isOpen:false, message: ''})
    setModal({isOpen:true, message: error.response.data.message || constants.APP_COPY_SOMETHING_WENT_WRONG})
  }
}

const checkItemQuantity = () => {
  if(lotDetails?.store_item) {
    if(Object.keys(lotDetails?.variants).length) {
      if(selectedVaraint && selectedVaraint.quantity_in_stock > 0) {
        return true
      }else return false
    }
    else if(lotDetails?.store_item.quantity_in_stock === 0) {
      return false
    }
  }
  return true
}

  return (
    <div className='lot_details'>
      <div className='lot_details_header'>
      <div className='product_name_section'>
         {
           (lotDetails?.auction?.market_place === "1") && 
           <h1 className='market_place_name' style={{ paddingTop:!isDesktop && "8px", color:primaryColor }}>
           {lotDetails?.auction?.title}
           </h1>
         }
         <h2 className='product_name' style={{ paddingTop:!isDesktop && "8px", color:primaryColor,fontSize:(lotDetails?.auction?.market_place === "1") ? '28px':'32px',fontWeight:(lotDetails?.auction?.market_place === "1") ? '400':'700',fontFamily:(lotDetails?.auction?.market_place === "1") ? 'Instrument Sans':'Instrument Sans Bold' }}>
           {lotDetails?.name}
         </h2>
      </div>
      {
        (eventStatus === 'live' && !['sold'].includes(lotDetails?.status) && checkItemQuantity()) &&
        <Tooltip
           title={
            <span className='favouritesTooltip'>
                {
                  isFavourite ? 'Remove from favorites' : 'Save to your favorites'
                }
            </span>} 
           placement='top'
         >
          <span>
             <FavouritesButton isFavourite={isFavourite} handleClick={handleAddToFavourites} />
          </span>
        </Tooltip>
      }
      </div>
      <h3 className='product_short_desc'style={{color:primaryColor}} >
        {lotDetails?.short_description}
      </h3>
    
      {lotDetails?.store_item_id &&
        Object.keys(lotDetails?.variants).length > 0 && (
          <VariantsSelector
            selected={selectedOptions}
            setSelected={setSelectedOptions}
            selectedVar={selectedVaraint}
            setSelectedVar={setSelectedVariant}
            options={lotDetails?.options}
            variants={Object.values(lotDetails?.variants)}
            setAuctionActive={setAuctionActive}
            start_date_event={lotDetails?.auction?.start_date}
            end_date_event={lotDetails?.auction?.end_date}
            status_event={lotDetails?.status}
            auctionComplete={auctionComplete}
            setHasQuantity={setHasQuantity}
          />
        )}
      {!lotDetails?.store_item_id &&
        bidDetails &&
        (bidDetails?.data?.bidCount >= 0 ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
          </div>
        ) : (
          <p
            style={{
              fontSize: 13,
              margin: 0,
              fontWeight: 600,
              textDecoration: 'underline',
              // color: 'var(--primary-blue-clr)',
            }}
          >
            {bidDetails?.data?.bidCount} Bids
          </p>
        ))}
        
          

      <hr style={{color:primaryColor}} />
    <div className='lot_stuff'>
      {/* <div className={lotDetails?.store_item_id && 'lot_stuff_price_quantity'}> */}
      <div>
      <div style={{display:'flex',flexDirection:'column', justifyContent:'space-between'}}>
      {/* <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}> */}
        {!lotDetails?.store_item_id && (
          <div style={{color:primaryColor,display:"flex",gap:"10px"}} className='lot_stuff_row'>
            <h3
              style={{
                // fontSize: 18,
                fontWeight: 800,
                // color: 'var(--primary-blue-clr)',
                color:primaryColor
              }}
            >
              $
              {bidDetails?.data?.bidCount > 0
                ? Number(bidDetails?.data?.bid_amount).toFixed(2)
                : Number(bidDetails?.data?.price).toFixed(2)}
            </h3>
            <p style={{ color:primaryColor }}> {+bidDetails?.data?.bidCount === 0 ? 'Starting': 'Current'} Bid</p>
          </div>
        )}

        {lotDetails?.store_item?.list_price && (
          <div className='lot_stuff_row' style={{color:primaryColor,display:"flex",gap:"10px"}}>
            <h3
              style={{
                // fontSize: 18,
                fontWeight: 800,
                // color: 'var(--primary-blue-clr)',
                textShadow: highlight ? "0px 0px 15px #1a385c" : "none",
                color: '#A29F9F',
                // color:primaryColor,
                 textDecoration: 'line-through',
              }}
            >
              ${Number(lotDetails?.store_item?.list_price).toFixed(2)}
            </h3>
            <p style={{ color:'#A29F9F', textShadow: highlight ? "0px 0px 15px #1a385c" : "none" }}>Retail</p>
          </div>
        )}


        {lotDetails?.buy_now_price && (
          <div className='lot_stuff_row' style={{color:primaryColor,display:"flex",gap:"10px"}}>
            <h3
              style={{
                // fontSize: 18,
                fontWeight: 800,
                // color: 'var(--primary-blue-clr)',
                color:primaryColor,
                textShadow: highlight ? "0px 0px 15px #1a385c" : "none"
              }}
            >
              ${!lotDetails?.store_item_id
                ? `${Number(lotDetails.buy_now_price).toFixed(2)}`
                : Object.keys(lotDetails?.variants).length === 0
                ? Number(lotDetails?.store_item.price).toFixed(2)
                : !selectedVaraint
                ? 'Not available'
                : `${Number(selectedVaraint.price).toFixed(2)}`}
            </h3>
            {lotDetails?.store_item?
            <p style={{ color:primaryColor, textShadow: highlight ? "0px 0px 15px #1a385c" : "none" }}>{lotDetails?.store_item?.list_price ? 'Boundless Price' : ''}</p>
            :
            <p style={{ color:primaryColor, textShadow: highlight ? "0px 0px 15px #1a385c" : "none" }}>Buy Now</p>
            }
          </div>
        )}
          {
            lotDetails?.reserve_price && +lotDetails?.reserve_price > 0 && +bidDetails?.data?.bidCount > 0 && +bidDetails?.data?.bid_amount < +lotDetails?.reserve_price &&
            <div className="reserve-not-met-text" >
               <p>Reserve Not Met</p>
              <TooltipComponent text={constants.APP_COPY_RESERVE_PRICE_INFO}>
                <InfoOutlined className="info-icon"/> 
              </TooltipComponent>
            </div>
          }

       
       </div>
          {/* in stocks or low stock alert */}
          <div style={{display:"flex", justifyContent:"space-between", marginTop:'5px'}}>
        {/* { new Date(lotDetails?.auction?.end_date) > new Date() && lotDetails?.store_item_id && (
            <p style={{fontSize: '12px', fontWeight: 400, lineHeight:"18px", marginBottom:0}}>
              {getQty()}  
            </p>
        )} */}

       { !auctionComplete && lotDetails?.show_stock_availability && lotDetails?.store_item_id ? (
            <p style={{fontSize: '12px', fontWeight: 400, lineHeight:"18px", marginBottom:0}}>
              {getQty()}  
            </p>
        )
        :<p></p>
      }

        {/* {lotDetails?.buy_now_price && (
          <div className='lot_stuff_row'>
            <p style={{ fontSize: 15, fontWeight: 600, textShadow: highlight ? "0px 0px 15px #1a385c" : "none" }}>Buy Now</p>
            <p
              style={{
                fontSize: 18,
                fontWeight: 800,
                color: 'var(--primary-blue-clr)',
                textShadow: highlight ? "0px 0px 15px #1a385c" : "none"
              }}
            >
              ${!lotDetails?.store_item_id
                ? `${Number(lotDetails.buy_now_price).toFixed(2)}`
                : Object.keys(lotDetails?.variants).length === 0
                ? Number(lotDetails?.store_item.price).toFixed(2)
                : !selectedVaraint
                ? 'Not available'
                : `${Number(selectedVaraint.price).toFixed(2)}`}
            </p>
          </div>
        )} */}
        <div className='lot_stuff_quantity_controls'>
      
        {/* Not particularly removed, just commented for future use. */}
        {/* { ((lotDetails?.store_item_id && lotDetails.auction?.market_place !== "1" && lotDetails.auction?.is_store) || (lotDetails?.store_item_id &&  lotDetails.auction?.market_place == "0"  && !lotDetails.auction?.is_store)) &&  (
          new Date(lotDetails?.auction?.start_date) > new Date() ? (
            <p style={{textAlign:'right'}} >Event not started</p>
          ) : (
            <p style={{
              // fontSize: 18,
              margin: 0,
              // fontWeight: 600,
              // color: 'var(--primary-blue-clr)',
              // textAlign: 'right'
            }}
            className='time_left_text'
            >
               {timeLeft ? (timeLeft.minutes
                ? `Ends in ${timeLeft.days} days ${timeLeft.hours} hrs ${timeLeft.minutes} mins`
                : timeLeft && !timeLeft.seconds && !timeLeft.minutes && !timeLeft.hours && !timeLeft.days
               ? 'Closed'
                : `Ends in ${timeLeft.days} days ${timeLeft.hours} hrs ${timeLeft.minutes} mins ${timeLeft?.seconds} sec`):"Loading..."}
            </p>
          )
        ) } */}


          {/* { new Date(lotDetails?.auction?.end_date) > new Date() && */}
          { !auctionComplete &&
             (lotDetails?.store_item && lotDetails?.buy_now_price === "0.00") ? 
                      <div style={{margin:0}} className='lot_stuff_row'>
                        {/* <div style={{display:"flex", alignItems: "center", justifyContent:'space-around'}}> */}
                        <div style={{display:"flex", alignItems: "center", justifyContent:'flex-end', gap:'11px'}}>
                        <span onClick={handleQuantityDecrement} className='quanatity_change_btn' >
                          {/* <Desktop>
                            <RemoveOutlinedIcon style={{ fontSize: "1rem"}}/>
                          </Desktop> */}
                          {/* <Mobile> */}
                            <RemoveOutlinedIcon style={{color:primaryColor, fontSize: "1rem"}}/>
                          {/* </Mobile> */}
                        </span>
                        <span style={{fontSize: "1.2rem", fontWeight: "600"}}>
                          {quantity}
                        </span>
                        <span onClick={handleQuantityIncrement} className='quanatity_change_btn'>
                        {/* <Desktop>
                          <AddIcon style={{fontSize: "1rem"}}/>
                        </Desktop> */}
                        {/* <Mobile> */}
                           <AddIcon style={{color:primaryColor, fontSize: "1rem"}}/>
                        {/* </Mobile> */}
                        </span>
                        </div>
                        <p className='select_qty_text' style={{marginTop:"7px"}}>Select Quantity</p>

                      </div>
                       :
                          ""
                    }

         </div>
         </div>
       </div>
<div className='lot_stuff_row'>
  <>
{/* <Mobile> */}
   {((!lotDetails?.store_item_id)) ? ((lotDetails?.store_item_id && lotDetails.auction?.market_place !== "1" && lotDetails.auction?.is_store) || (lotDetails?.store_item_id &&  lotDetails.auction?.market_place == "0"  && !lotDetails.auction?.is_store)) &&  (
          new Date(lotDetails?.auction?.start_date) > new Date() ? (
            <p className='time_left_text' style={{margin: 0}} >Event not started</p>
          ) : (
            <p style={{
              // fontSize: 18,
              margin: 0,
              // fontWeight: 600,
              // color: 'var(--primary-blue-clr)',
              // textAlign: 'right'
            }}
            className='time_left_text'
            >
               {timeLeft ? (timeLeft.minutes
                ? `Ends in ${timeLeft?.days ? `${timeLeft.days}${` `} days`: ``} ${timeLeft?.hours ? `${timeLeft.hours}${` `} hrs`:``} ${timeLeft?.minutes ? `${timeLeft.minutes}${` `} mins`: ``}`
                : timeLeft && !timeLeft.seconds && !timeLeft.minutes && !timeLeft.hours && !timeLeft.days
               ? 'Closed'
                : `Ends in ${timeLeft?.days ? `${timeLeft.days}${` `} days`:``}  ${timeLeft?.hours ?`${timeLeft.hours}${` `} hrs`:``} ${timeLeft?.minutes ? `${timeLeft.minutes}${` `} mins`:``} ${timeLeft?.seconds ? `${timeLeft.seconds}${` `} sec`:``}`):"Loading..."}
            </p>
          )
        ) : ""}
{/* </Mobile> */}
{/* <Desktop>   
      { ((lotDetails?.store_item_id && lotDetails.auction?.market_place !== "1" && lotDetails.auction?.is_store) || (lotDetails?.store_item_id &&  lotDetails.auction?.market_place == "0"  && !lotDetails.auction?.is_store)) &&  (
          new Date(lotDetails?.auction?.start_date) > new Date() ? (
            <p style={{textAlign:'right'}} >Event not started</p>
          ) : (
            <p style={{
              fontSize: 18,
              margin: 0,
              fontWeight: 600,
              color: 'var(--primary-blue-clr)',
              textAlign: 'right'
            }}
            >
               {timeLeft ? (timeLeft.minutes
                ? `Ends in ${timeLeft.days} days ${timeLeft.hours} hrs ${timeLeft.minutes} mins`
                : timeLeft && !timeLeft.seconds && !timeLeft.minutes && !timeLeft.hours && !timeLeft.days
               ? 'Closed'
                : `Ends in ${timeLeft.days} days ${timeLeft.hours} hrs ${timeLeft.minutes} mins ${timeLeft?.seconds} sec`):"Loading..."}
            </p>
          )
        ) }
</Desktop> */}
</>
</div>
        {!lotDetails?.store_item_id && (
          <div className='lot_stuff_row auction_lot_stuff_row '>
            <div style={{display:"flex", alignItems:"center", gap:'20px', marginTop: "17px"}}>
            <p
              style={{
                fontSize: 18,
                margin: 0,
                // fontWeight: 600,
                textDecoration: 'underline',
                // color: 'var(--primary-blue-clr)',
                fontStyle:"normal",
                fontWeight:400,
                lineHeight:'24px',
                letterSpacing:"-0.36px",
                color:secondaryColor
              }}
            >
              <span
                onClick={() =>
                  navigate(
                    `/lot/auction/${auctionId}/item/${itemId}/history`
                  , {state:{itemName:lotDetails?.name}})
                }
              >
                {bidDetails?.data?.bidCount} Bids
              </span>
            </p>

                  {
                      (!lotDetails.store_item &&  <div style={{textAlign: "right"}}>
                      <button onClick={refreshPage} style={{padding: "3px 13px", color:whiteColor, background:primaryColor}}>{
                        loading ?  <span style={{padding:"0px 7px"}}>Loading...</span>: <span><RefreshIcon sx={{marginRight: "5px",fontSize:'16px'}} /> Refresh</span>
                      }</button>
                    </div>)
                    }

            </div>{ (!lotDetails?.store_item_id) ?
             (new Date(lotDetails?.start_date) > new Date()) ? (
              <p>{moment(lotDetails?.start_date)-new Date()<60*60*1000?"Auction starting soon":"Auction not started"}</p>
            ) : (
              <p style={{
                // fontSize: 18,
                // margin: 0,
                marginTop: '25px',
                // fontWeight: 600,
                color: primaryColor,
              }}
              className='time_left_text'
              >
               {timeLeft ? (timeLeft.minutes
                ? `Ends in ${timeLeft?.days ? `${timeLeft.days}${` `} days`: ``} ${timeLeft?.hours ? `${timeLeft.hours}${` `} hrs`:``} ${timeLeft?.minutes ? `${timeLeft.minutes}${` `} mins`: ``}`
                : timeLeft && !timeLeft.seconds && !timeLeft.minutes && !timeLeft.hours && !timeLeft.days
               ? 'Closed'
                : `Ends in ${timeLeft?.days ? `${timeLeft.days}${` `} days`:``}  ${timeLeft?.hours ?`${timeLeft.hours}${` `} hrs`:``} ${timeLeft?.minutes ? `${timeLeft.minutes}${` `} mins`:``} ${timeLeft?.seconds ? `${timeLeft.seconds}${` `} sec`:``}`):"Loading..."}
              </p>
            ) : ""}
          </div>
        )}
         {/* { new Date(lotDetails?.auction?.end_date) > new Date() &&
           (lotDetails?.store_item && lotDetails?.buy_now_price === "0.00") ? 
                      <div className='lot_stuff_row' style={{marginTop: "20px"}}>
                        <div style={{fontSize: 15, fontWeight: 600}}>Select Quantity</div>
                        <div style={{display:"flex", alignItems: "center"}}>
                        <span onClick={handleQuantityDecrement} className='quanatity_change_btn' >
                          <RemoveOutlinedIcon style={{color:"white", fontSize: "1rem"}}/></span>
                        <span style={{fontSize: "1.2rem", fontWeight: "600"}}>{quantity}</span>
                        <span onClick={handleQuantityIncrement} className='quanatity_change_btn'>
                          <AddIcon style={{color:"white", fontSize: "1rem"}}/></span>
                        </div>

                      </div>
                       :
                          ""
                    }  */}
                    {/* {
                      (!lotDetails.store_item &&  <div style={{textAlign: "right"}}>
                      <button onClick={refreshPage} style={{padding: "8px 16px"}}>{
                        loading ?  <span style={{padding:"0px 7px"}}>Loading...</span>: <span><RefreshIcon sx={{marginRight: "5px",fontSize:'16px'}} /> Refresh</span>
                      }</button>
                    </div>)
                    } */}

    {getCauseAmount() > 0 && new Date(lotDetails?.auction?.end_date) > new Date() && lotDetails?.auction_item_amount_given_dialog === 1 && lotDetails?.auction?.amount_given_dialog === 1 ?
     <>
        {(getCauseAmount() > 0  && (lotDetails?.store_item_id ? !auctionComplete : (timeLeft?.seconds || timeLeft?.minutes  || timeLeft?.hours) ) && lotDetails.can_choose_cause === 0 && causeName) ? (
          <div>
            <hr />
            <p style={{textAlign: 'center', backgroundColor: '#D4B4B880', padding: '10px', fontSize: 18, fontWeight: 700,color: primaryColor, lineHeight:'24px', fontStyle:'normal'}}>
              ${getCauseAmount().toFixed(2)} goes directly to {causeName}!
            </p>
          </div>
        ): 
        (lotDetails?.user_order_auction_item_xrefs?.length && !lotDetails?.store_item_id) ?

        <div>
        <hr />
        <p  style={{textAlign: 'center', backgroundColor: '#D4B4B880', padding: '10px', fontSize: 18, fontWeight: 700,color: primaryColor, lineHeight:'24px', fontStyle:'normal'}}>
          ${getCauseAmount().toFixed(2)} goes to the cause of {lotDetails?.user_order_auction_item_xrefs[0]?.cause?.legal_name ? lotDetails?.user_order_auction_item_xrefs[0]?.cause?.legal_name : causeName }! 
        </p>
      </div>
      :

      // for showing if the product is from another marketplace
      (lotDetails?.status === 'active' && !auctionComplete  && xrefCauseData) ?
          <div>
            <hr />
            <p  style={{textAlign: 'center', backgroundColor: '#D4B4B880', padding: '10px', fontSize: 18, fontWeight: 700,color: primaryColor, lineHeight:'24px', fontStyle:'normal'}}>
              ${getCauseAmount().toFixed(2)} goes directly to {causeName}!
            </p>
          </div>
        :
       ((lotDetails?.status === 'active' && new Date(lotDetails?.end_date) > new Date() && lotDetails.can_choose_cause === 1) ||
       ( lotDetails?.store_item_id && lotDetails?.store_item.quantity_in_stock > 0 && !auctionComplete && lotDetails.can_choose_cause === 1 ) ) ?
        <div>
        <hr />
        <p  style={{textAlign: 'center', backgroundColor: '#D4B4B880', padding: '10px', fontSize: 18, fontWeight: 700,color: primaryColor, lineHeight:'24px', fontStyle:'normal'}}>
          ${getCauseAmount().toFixed(2)} goes to the cause of your choice
        </p>
      </div>
      :
      (lotDetails?.store_item_id && auctionComplete && lotDetails?.can_choose_cause === 0 && causeName) ?
      <div>
            <hr />
            <p  style={{textAlign: 'center', backgroundColor: '#D4B4B880', padding: '10px', fontSize: 18, fontWeight: 700,color: primaryColor, lineHeight:'24px', fontStyle:'normal'}}>
              ${getCauseAmount().toFixed(2)} goes directly to {causeName}!
            </p>
          </div>
          :
      null
      } 
      </> 
      : null}

      {/* {
        new Date(lotDetails?.auction?.end_date) < new Date() &&
        <>
          <hr />
        <div>
            <p style={{textAlign: 'center', backgroundColor: '#30313a', padding: '10px', fontSize: 18, fontWeight: 800,color: 'white',borderRadius:'15px'}}>
              Event Completed!
            </p>
          </div>
        </>
      } */}

      {
        auctionComplete &&
        <>
          <hr />
        <div>
            <p style={{textAlign: 'center', backgroundColor: '#30313a', padding: '10px', fontSize: 18, fontWeight: 800,color: 'white',borderRadius:'15px'}}>
              Event Completed!
            </p>
          </div>
        </>
      }


      </div>
      {/* <Link to="/">
            <button type="button">Watch</button>
        </Link> */}
    </div>
  );
};

export default ItemInfo;
