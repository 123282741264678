import { useLocation } from 'react-router-dom';
import Event from '../event/event.jsx';
import EventBrowseItems from '../EventBrowseItems/EventBrowseItems.jsx';
import { mobileStyle } from '../../Home.jsx';

function EventPage() {
    const search = useLocation().search;
    const item_type = new URLSearchParams(search).get('item-type');
    return (
        item_type ? <EventBrowseItems backgroundColor={mobileStyle.backgroundColor[4]} /> : <Event />
    )
}

export default EventPage;